<template>
  <div class="animated fadeIn">
    <div id="snackbar"></div>
    <b-row>
      <b-col sm="12">
        <b-card>
          <div slot="header">
            <strong>Add Screen</strong>
            <small>Form</small>
          </div>

          <b-form v-on:submit.prevent.self="submit">
            <b-row>
              <b-col sm="3">
                <b-form-group>
                  <label for="title">
                    Title
                    <span class="text-danger">*</span>
                  </label>
                  <b-form-input
                    type="text"
                    required
                    v-model="screen.ScreenName"
                    id="screenName"
                    placeholder="Screen Name"
                  ></b-form-input>
                </b-form-group>
              </b-col>

              <b-col sm="3">
                <b-form-group>
                  <label for="title">
                    Screen Type
                    <span class="text-danger">*</span>
                  </label>
                  <b-form-input
                    type="text"
                    required
                    v-model="screen.ScreenType"
                    id="ScreenType"
                    placeholder="ScreenType"
                  ></b-form-input>
                </b-form-group>
              </b-col>

              <b-col sm="3">
                <b-form-group>
                  <label for="organization">
                    Organization
                    <span class="text-danger">*</span>
                  </label>
                  <b-form-select
                    required
                    @change.native="getCinemaList($event.target.value)"
                    v-model="screen.OrganizationID"
                    id="organization"
                    :plain="true"
                    value-field="OrganizationID"
                    text-field="OrganizationName"
                    :options="organizations"
                  >
                    <template slot="first">
                      <option :value="0" disabled>-- Select Organization --</option>
                    </template>
                  </b-form-select>
                </b-form-group>
              </b-col>

              <b-col sm="3">
                <b-form-group>
                  <label for="cinemas">
                    Cinema
                    <span class="text-danger">*</span>
                  </label>
                  <b-form-select
                    required
                    v-model="screen.CinemaID"
                    id="cinemas"
                    :plain="true"
                    value-field="CinemaID"
                    text-field="CinemaName"
                    :options="cinemas"
                  >
                    <template slot="first">
                      <option :value="0" disabled>-- Select Cinema --</option>
                    </template>
                  </b-form-select>
                </b-form-group>
              </b-col>
            </b-row>

            <b-row>
              <b-col sm="3">
                <b-form-group>
                  <label for="description">Description</label>
                  <b-form-input
                    type="text"
                    required
                    v-model="screen.Description"
                    id="id"
                    placeholder="Description"
                  ></b-form-input>
                </b-form-group>
              </b-col>

              <b-col sm="3">
                <b-form-group>
                  <label for="status">Status</label>
                  <br />
                  <c-switch
                    class="mx-1"
                    color="primary"
                    v-model="screen.ScreenIsActive"
                    defaultChecked
                    variant="3d"
                    label
                    v-bind="labelIcon"
                  />
                </b-form-group>
              </b-col>

              <b-col sm="3">
                <!-- {{ screen.SeatClassName.length }} -->
                <div v-for="(item, index) in screen.SeatClassName" :key="item+index">
                  <div style="display:flex;align-items: center;justify-content: space-between;">
                    <div>
                      <b-form-group id="youtubelinksfields">
                        <label v-if="index == 0" for="classes">
                          Class
                          <span class="text-danger">*</span>
                        </label>
                        <!-- <b-form-input required v-for="(index, inx) in autoInput" :key="index+inx" :class="index==1 ? '' : 'mt-2'"  type="text" v-model="screen.SeatClassName[inx]" :disabled="!statusFlag.classUpdateFlag" id="classes" placeholder="Class"></b-form-input> -->
                        <b-form-input
                          required
                          :class="index == 0 ? '' : 'mt-2'"
                          type="text"
                          v-model="item.Lable"
                          id="classes"
                          placeholder="Class"
                        ></b-form-input>
                      </b-form-group>
                    </div>
                    <div v-if="statusFlag.classUpdateFlag">
                      <span v-if="index == 0">
                        <i
                          class="fa fa-plus-square-o text-success mt-3 mx-2"
                          @click="addClassField()"
                        ></i>
                      </span>
                      <span v-if="index != 0" style="margin-top: -15px;">
                        <i
                          class="fa fa-minus-square-o text-danger mx-2"
                          @click="removeClassField(index)"
                        ></i>
                      </span>
                    </div>
                  </div>
                </div>
              </b-col>

              <!-- <b-col v-if="statusFlag.classUpdateFlag" sm="2">
              
              </b-col>-->
            </b-row>

            <b-row>
              <b-col sm="6">
                <b-button type="submit" size="sm" variant="primary">
                  <i class="fa fa-dot-circle-o"></i>
                  {{ commontext.submitButton }}
                </b-button>
              </b-col>
              <b-col sm="6">
                <b-button @click="resetForm" size="sm" variant="success">
                  <i class="fa fa-dot-circle-o"></i> Reset
                </b-button>
              </b-col>
            </b-row>
          </b-form>
        </b-card>
      </b-col>
    </b-row>

    <b-modal
      id="ModalConfirmationOnSubmit"
      ref="ModalConfirmationOnSubmit"
      @ok="handleModalOkay"
      :title="modalObject.title"
      class="center-footer"
      footer-class="justify-content-center border-0"
      cancel-variant="outline-danger"
      ok-variant="outline-success"
      centered
      hide-header
      no-close-on-esc
      no-close-on-backdrop
    >
      <div class="d-block text-center">
        <div>{{ modalObject.message }}</div>
      </div>
    </b-modal>
  </div>
</template>

<script>
import OrganizationService from "@/services/OrganizationService";
import CinemaService from "@/services/CinemaService";
import ScreenService from "@/services/ScreenService";
import { Switch as cSwitch } from "@coreui/vue";
export default {
  name: "AddScreen",
  components: {
    cSwitch
  },
  data() {
    return {
      commontext: {
        submitButton: "Submit"
      },
      statusFlag: {
        classUpdateFlag: true
      },
      screen: {
        Flag: 1,
        ScreenID: null,
        ScreenName: "",
        OrganizationID: 0,
        CinemaID: 0,
        Description: "",
        Threshold: "",
        Expiry: "",
        SeatClassName: [
          {
            ID: "",
            Lable: ""
          }
        ],
        ScreenType: "",
        ScreenIsActive: true
      },
      autoInput: 1,
      organizations: [],
      cinemas: [],
      labelIcon: {
        dataOn: "\u2713",
        dataOff: "\u2715"
      }
    };
  },
  mounted() {
    const { ScreenID } = this.$route.query;
    this.getOrganizationList();
    if (ScreenID) {
      this.getScreenListById(ScreenID);
    }
  },
  methods: {
    getScreenListById: function(ScreenID) {
      // let res = await ScreenService.getScreenByID(ScreenID);

      let payload = {
        ScreenID
      };

      ScreenService.getScreenByID(payload)
        .then(response => {
          const { data } = response;
          if (data.Status && data.ScreenList.length > 0) {
            this.screen.SeatClassName = [];
            this.commontext.submitButton = "Update";
            this.statusFlag.classUpdateFlag = false;
            this.getCinemaList(data.ScreenList[0].OrganizationID);
            this.screen = data.ScreenList[0];
            this.screen.Flag = 2;
            console.log("object :", data.ScreenList[0].SeatClassName);
            // var seatclasses = data.ScreenList[0].SeatClassName.split(",");
            // this.screen.SeatClassName = [];
            // console.log("data :", data);
            // this.screen.SeatClassName =
            //   data.ScreenType && data.ScreenType.length > 0
            //     ? data.ScreenType
            //     : [];
            // Added new
            this.screen.SeatClassName = data.ScreenList[0].SeatClassName;
          } else {
            this.showMessageSnackbar(data.Message);
          }
        })
        .catch(error => {
          console.log("error :", error);
          this.showMessageSnackbar(
            "Oops! Something went wrong. kindly contact your administrator"
          );
        });
    },
    resetForm: function() {
      this.statusFlag.classUpdateFlag = true;
      this.commontext.submitButton = "Submit";
      this.screen = {
        Flag: 1,
        ScreenID: null,
        ScreenName: "",
        OrganizationID: 0,
        CinemaID: 0,
        ScreenType: "",
        Description: "",
        SeatClassName: [
          {
            ID: "",
            Lable: ""
          }
        ],
        ScreenIsActive: true
      };
      this.cinemas = [];
      this.autoInput = 1;

      window.location.replace(window.location.href.split("?")[0]);
    },
    getOrganizationList: function() {
      this.organizations = [];
      CinemaService.getOrganization()
        .then(response => {
          const { data } = response;
          if (data.Status && data.OrganizationList.length > 0) {
            this.organizations = data.OrganizationList;
          } else {
            this.showMessageSnackbar(data.Message);
          }
        })
        .catch(error => {
          this.showMessageSnackbar(
            "Oops! Something went wrong. kindly contact your administrator"
          );
        });
    },
    getCinemaList: function(OrgID) {
      this.cinemas = [];
      this.screen.CinemaID = 0;
      let payload = {
        OrgID
      };
      CinemaService.getActiveCinemas(payload)
        .then(response => {
          const { data } = response;
          if (data.Status && data.CinemaList.length > 0) {
            this.cinemas = data.CinemaList;
          } else {
            this.showMessageSnackbar(data.Message);
          }
        })
        .catch(error => {
          this.showMessageSnackbar(
            "Oops! Something went wrong. kindly contact your administrator"
          );
        });
    },
    addClassField: function() {
      // this.autoInput++;
      this.screen.SeatClassName.push({
        ID: 0,
        Lable: ""
      });
    },
    removeClassField: function(index) {
      // if (this.autoInput > 1) {
      //   this.screen.SeatClassName.splice(
      //     this.screen.SeatClassName.length - 1,
      //     1
      //   );
      //   this.autoInput--;
      // }
      this.screen.SeatClassName.splice(index, 1);
    },
    validation: function() {
      if (this.screen.ScreenName == "") {
        this.showMessageSnackbar("Screen name cannot be empty.");
        return false;
      } else if (this.screen.ScreenType == "") {
        this.showMessageSnackbar("Screen Type canot be empty.");
      } else if (this.screen.OrganizationID == 0) {
        this.showMessageSnackbar("Please select the Organization.");
        return false;
      } else if (this.screen.CinemaID == 0) {
        this.showMessageSnackbar("Please select the Cinema.");
        return false;
      } else if (this.screen.Description == "") {
        this.showMessageSnackbar("Screen Description Cannot be empty.");
        return false;
      } else {
        return true;
      }
    },
    submit: function() {
      if (this.validation()) {
        let payload = {
          title: "Confirmation",
          message: "Please re-check the data before submit"
        };
        this.showSubmitConfirmation(payload);
      }
    },
    submitFinalData: function() {
      this.closeModal("ModalConfirmationOnSubmit");

      let sendArray = {};
      Object.assign(sendArray, this.screen);
      // let string = "";
      // this.screen.SeatClassName.map((x, index) => {
      //   return (string =
      //     index == this.screen.SeatClassName.length - 1
      //       ? string + x
      //       : string + x + ",");
      // });
      // sendArray["SeatClassName"] = string;
      ScreenService.addScreen(sendArray)
        .then(response => {
          const { data } = response;
          data.Message ? this.showMessageSnackbar(data.Message) : "";
          if (data.Status) {
            this.resetForm();
          }
        })
        .catch(error => {
          this.showMessageSnackbar(
            "Oops! Something went wrong. kindly contact your administrator"
          );
        });
    },
    // Show Confirmmation Dialog
    handleModalOkay: function(evt) {
      // evt.preventDefault();
      this.modalObject.count = 1;
      this.submitFinalData();
    },
    showSubmitConfirmation: function(payload) {
      this.modalObject.title = payload.title; // "Confirmation";
      this.modalObject.message = payload.message; // "Please re-check the data before submit";
      this.openModal("ModalConfirmationOnSubmit");
    },
    openModal: function(ref) {
      this.$refs[ref].show();
    },
    closeModal: function(ref) {
      this.$refs[ref].hide();
      this.modalObject.title = ""; // "Confirmation";
      this.modalObject.message = ""; // "Please re-check the data before submit";
    },
    // Show Confirmmation Dialog End
    // Show Error Message
    showMessageSnackbar: function(message) {
      let x = document.getElementById("snackbar");
      x.className = "show";
      x.innerHTML = message;
      setTimeout(function() {
        x.className = x.className.replace("show", "");
      }, 3000);
    }
  }
};
</script>

<style>
.fa-plus-square-o,
.fa-minus-square-o {
  font-size: 20px;
  cursor: pointer;
}
</style>
